import cookie from 'cookie'
import camelcaseKeys from 'camelcase-keys'
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'js-cookie'
import getConfig from 'utils/getConfig'

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig() || {}

const clientSide = typeof window !== 'undefined'

const cookieExpiryTimes = {
  session: 1000 * 60 * 60 * 24 * 90,
  signedIn: 1000 * 60 * 60 * 24,
  signedInRememebered: 1000 * 60 * 60 * 24 * 90,
}

const cookieOpts = {
  httpOnly: false,
  secure: process.env.NODE_ENV === 'production',
  domain: publicRuntimeConfig.COOKIE_DOMAIN,
  sameSite: publicRuntimeConfig.COOKIE_SAMESITE,
}

const toQueryString = (param) => {
  if (!param) {
    return ''
  }

  if (typeof param !== 'object') {
    param = { param }
  }

  return Object.keys(param)
    .map((key, index) => `${index ? `&` : `?`}${key}=${param[key]}`)
    .join('')

  // return obj ? `?${querystring.stringify(obj)}` : ``
}

export class ApiClient {
  constructor(cookies) {
    this.baseUrl = publicRuntimeConfig.PURPOSELY_API_URL
    this.sessionData = undefined
    this.inFlight = {}
    this.cookies = cookies

    if (cookies) {
      this.readCookies()
    }
  }

  getUuid() {
    if (clientSide) {
      // do client stuff
      const clientcookie = cookie.parse(document.cookie)
      this.uuid = clientcookie.UUID
      return
    }

    if (!this.cookies) {
      this.uuid = ''
      return
    }

    let uuid = this.cookies.get('UUID')

    if (!uuid) {
      uuid = uuidv4()
    }

    if (uuid !== this.uuid) {
      this.cookies.set('UUID', uuid, {
        ...cookieOpts,
        expires: new Date(new Date().getTime() + cookieExpiryTimes.session),
      })

      this.uuid = uuid
    }
  }

  updateSessionToken(token, params) {
    this.sessionToken = token

    Cookies.set('SESSION_TOKEN', token, {
      ...cookieOpts,
      expires: params?.rememberMe ? 90 : 1,
    })
  }

  getSessionToken() {
    if (clientSide) {
      // do client stuff
      const clientcookie = cookie.parse(document.cookie)
      this.sessionToken = clientcookie.SESSION_TOKEN || ''

      return
    }

    if (!this.cookies) {
      this.sessionToken = ''

      return
    }

    const token = this.cookies.get('SESSION_TOKEN')

    if (token && token !== this.sessionToken) {
      this.sessionToken = token
    }
  }

  getSelectedMarket() {
    if (clientSide) {
      // do client stuff
      const clientcookie = cookie.parse(document.cookie)
      this.selectedMarket = clientcookie.MARKET || ''

      return
    }

    if (!this.cookies) {
      this.selectedMarket = ''

      return
    }

    const selectedMarket = this.cookies.get('MARKET')

    if (selectedMarket && selectedMarket !== this.selectedMarket) {
      this.selectedMarket = selectedMarket
    }
  }

  readCookies() {
    this.getUuid()
    this.getSessionToken()
    this.getSelectedMarket()
  }

  // eslint-disable-next-line class-methods-use-this
  log(...rest) {
    if (process?.env?.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log(...rest)
    }
  }

  async request(method = 'GET', endpoint, data, abortable = false, tempToken = '') {
    if (clientSide) {
      this.readCookies()
    }

    const inFlightName = (method + endpoint).replace(/[^a-zA-Z0-9 !?]+/g, '')

    if (abortable && this.inFlight[inFlightName]) {
      this.inFlight[inFlightName].abort()
    }

    let signal

    if (abortable && clientSide) {
      const currentAbortController = new AbortController()
      this.inFlight[inFlightName] = currentAbortController

      signal = currentAbortController.signal
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    this.log('Request', method, endpoint)

    if (tempToken) {
      this.log('Using temp token', tempToken)
      headers.Authorization = `Bearer ${tempToken}`
    } else if (this.sessionToken) {
      this.log('Using session token', this.sessionToken)
      headers.Authorization = `Bearer ${this.sessionToken}`
    } else if (serverRuntimeConfig?.PURPOSELY_API_KEY) {
      this.log('Using app token')
      headers.Authorization = `Bearer ${serverRuntimeConfig?.PURPOSELY_API_KEY}`
    }

    const response = await fetch(`${this.baseUrl}/api${endpoint}`, {
      mode: 'cors',
      headers,
      method,
      body: ['POST', 'PUT'].includes(method) ? JSON.stringify(data) : undefined,
      signal,
    })

    if (response.status >= 400) {
      const error = new Error(`${response.status} ${response.statusText}`)
      const json = await response.json()

      if (json) {
        error.data = json
      }

      throw error
    }

    return response
  }

  async json(...args) {
    const response = await this.request(...args)

    if (response.status === 204) {
      return ''
    }

    const json = await response.json()

    const dataProp = json.data || json
    const data = camelcaseKeys(dataProp, { deep: true })

    return data
  }

  async init(opts) {
    const { refresh, url, urlLanguage, ipAddress } = opts

    if (this.sessionToken && this.sessionData && !refresh) {
      return this.sessionData
    }

    this.sessionToken = ''

    const deviceOs = (typeof navigation !== 'undefined' && navigator.userAgentData.platform) || 'NA'

    const data = await this.json('POST', '/init/client', {
      uuId: this.uuid,
      deviceOs,
      url,
      urlLanguage,
      ipAddress,
    })

    if (data.token) {
      this.sessionData = data
      this.sessionToken = data.token

      if (!clientSide) {
        this.cookies.set('SESSION_TOKEN', data.token, {
          ...cookieOpts,
          expires: new Date(new Date().getTime() + cookieExpiryTimes.session),
        })
      } else {
        this.updateSessionToken(data.token)
      }
    }

    if (data.affiliateCode) {
      this.cookies.set('AFFILIATE_CODE', data.affiliateCode, {
        ...cookieOpts,
        expires: new Date(new Date().getTime() + cookieExpiryTimes.session),
      })
    }

    return data
  }

  /* Public */

  async hello() {
    const data = await this.json('POST', '/session/hello')

    return data
  }

  // eslint-disable-next-line class-methods-use-this
  async setMarket({ marketCode }) {
    Cookies.set('MARKET', marketCode, {
      ...cookieOpts,
      expires: 90,
    })
  }

  async getDeliveryCountries() {
    const data = await this.json('GET', '/session/market_delivery_countries')

    return data
  }

  async translations() {
    const data = await this.json('GET', '/session/translations')

    return data
  }

  async register({ email, password }) {
    const data = await this.json('POST', '/session/register', { email, password })

    return data
  }

  async ambassadorApplication(params) {
    const data = await this.json('POST', '/session/affiliate_application', params)

    return data
  }

  async signIn(params) {
    const data = await this.json('POST', '/session/sign-in', params)

    if (data.token) {
      this.log('Sign in successful', data.token)

      this.updateSessionToken(data.token, params)
    }

    if (data.widgetToken) {
      this.widgetToken = data.widgetToken

      Cookies.set('WIDGET_TOKEN', data.widgetToken, {
        ...cookieOpts,
        expires: params.rememberMe ? 90 : 1,
      })
    }

    return data
  }

  async signOut(all) {
    await this.request('POST', `/session/sign-out${all ? `/all` : ``}`)

    this.log('Remove cookies')

    Cookies.remove('SESSION_TOKEN', cookieOpts)
    Cookies.remove('WIDGET_TOKEN', cookieOpts)
    Cookies.remove('UUID', cookieOpts)

    window.location.reload()
  }

  async activate(activationToken) {
    const data = await this.json('POST', '/user/activate', null, false, activationToken)

    return data
  }

  async deactivate() {
    const data = await this.json('POST', '/user/deactivate')

    return data
  }

  async resetPasswordRequest({ email }) {
    const data = await this.json('POST', '/session/reset-password-request', { email })

    return data
  }

  async resetSessionPassword(params, token) {
    const data = await this.json('POST', '/session/reset-password', params, false, token)

    if (data.token) {
      this.log('resetSessionPassword', data.token)

      this.updateSessionToken(data.token, params)
      return data.token
    }
    return data
  }

  async resetUserPassword(passwords, token) {
    const data = await this.json('POST', '/user/reset-password', passwords, false, token)

    return data
  }

  async checkUser() {
    const data = await this.json('GET', '/session/token/check')

    return data
  }

  // USER
  async getUser() {
    const data = await this.json('GET', '/user')

    return data
  }

  async updateUser(params) {
    const data = await this.json('PUT', '/user', params)

    return data
  }

  // ADDRESSES
  async getAddressTypes() {
    const data = await this.json('GET', '/session/address_types')

    return data
  }

  async addAddress(params) {
    const data = await this.json('POST', '/user/addresses', params)

    return data
  }

  async changeAddress(id, params) {
    const data = await this.json('PUT', `/user/addresses/${id}`, params)

    return data
  }

  async removeAddress(id) {
    const data = await this.json('DELETE', `/user/addresses/${id}`)

    return data
  }

  // CONTACTS
  async getContactTypes() {
    const data = await this.json('GET', '/contact_types')

    return data
  }

  async getContacts() {
    const data = await this.json('GET', '/user/contacts')

    return data
  }

  async addContact(params) {
    const data = await this.json('POST', '/user/contacts', params)

    return data
  }

  async changeContact(id, params) {
    const data = await this.json('PUT', `/user/contacts/${id}`, params)

    return data
  }

  async removeContact(id) {
    const data = await this.json('DELETE', `/user/contacts/${id}`)

    return data
  }

  async checkEmail(params) {
    const data = await this.json('GET', `/session/user${toQueryString(params)}`)

    return data
  }

  // AFFILIATE CODE
  async generateAffiliateCode(params) {
    const data = await this.json('GET', `/session/user/affiliate_code${toQueryString(params)}`)

    return data.affiliateCode
  }

  async saveAffiliateCode(params) {
    const data = await this.json('POST', `/session/user/affiliate_code${toQueryString(params)}`)

    return data.affiliateCode
  }

  // PAYMENT METHODS
  async getAvailableBanks() {
    const data = await this.json('GET', '/user/banks')

    return data
  }

  async getPaymentMethods() {
    const data = await this.json('GET', '/user/payment_methods')

    return data
  }

  async createPaymentMethod(params) {
    const data = await this.json('POST', '/user/payment_methods', params)

    return data
  }

  async addPaymentMethod(params) {
    const data = await this.json('POST', '/user/payment_method', params)

    return data
  }

  async availablePaymentMethods() {
    const data = await this.json('GET', '/user/available_payment_methods')

    return data
  }

  async updatePaymentMethod(id, params) {
    const data = await this.json('PUT', `/user/payment_methods/${id}`, params)

    return data
  }

  async deletePaymentMethod(id) {
    const data = await this.json('DELETE', `/user/payment_methods/${id}`)

    return data
  }

  // CONSENTS
  async getListOfAvailablConsents() {
    const data = await this.json('GET', '/user/custom_info/CONSENTS')

    return data
  }

  async createConsentValue(params) {
    const data = await this.json('POST', '/user/custom_info', params)

    return data
  }

  async updateConsentValue(params) {
    const data = await this.json('PUT', `/user/custom_info`, params)

    return data
  }

  // VOUCHERS
  async getVouchers() {
    const data = await this.json('GET', '/user/vouchers')

    return data
  }

  async getConsumablesVouchers() {
    const data = await this.json('GET', '/user/vouchers/consumables')

    return data
  }

  // BONUS
  async addBonusRequest(periodId) {
    const data = await this.json(
      'POST',
      `/user/bonus_payout/request${toQueryString(periodId && { periodId })}`,
    )

    return data
  }

  async getPayoutStatus() {
    const data = await this.json('GET', '/user/bonus_payout/status')

    return data
  }

  async getPSCOrderCalculation(periodId) {
    const data = await this.json(
      'GET',
      `/user/psc_order_calculation_history${toQueryString(periodId && { periodId })}`,
    )

    return data
  }

  async userDashboard() {
    const data = await this.json('GET', '/user/dashboard')

    return data
  }

  async newsletter(email) {
    const data = await this.json('POST', '/session/newsletter/subscribe', { email })

    return data
  }

  // PRODUCTS
  async products() {
    const data = await this.json('GET', '/session/products')

    return data
  }

  async productById(productId) {
    const data = await this.json('GET', `/session/products/id/${productId}`)

    return data?.[0]
  }

  async productBySlug(slug) {
    const data = await this.json('GET', `/session/products/${slug}`)

    return data?.[0]
  }

  // REVIEWS

  async getReviews(params) {
    const data = await this.json(
      'GET',
      `/session/yotpo/reviews?product_code_list=${params.productCodes}&page=${params.page}&page_count=${params.pageCount}`,
    )

    return data
  }

  // - Subscriptions
  async getSubscriptions(statusId) {
    const data = await this.json('GET', '/user/product_subscriptions', statusId)

    return data
  }

  async getSubscriptionStatuses(statusId) {
    const data = await this.json('GET', '/product_subscription_statuses', statusId)

    return data
  }

  async createSubscription(params) {
    const data = await this.json('POST', `/user/product_subscriptions`, params)

    return data
  }

  async updateSubscription(subscriptionId, params) {
    const data = await this.json('PUT', `/user/product_subscriptions/${subscriptionId}`, params)

    return data
  }

  // MyBioma
  async getMyGutItems() {
    const data = await this.json('GET', `/my-bioma/categories`)

    return data
  }

  async registerMyBiomaKit(params) {
    const data = await this.json('PUT', `/session/my-bioma`, params)

    return data
  }

  async updateMyBiomaKit(params) {
    const data = await this.json('PUT', `/session/my-bioma`, params)

    return data
  }

  async getMyBiomaKits() {
    const data = await this.json('GET', `/my-bioma/kits`)

    return data
  }

  // BASKET
  async updateBasket(params) {
    const data = await this.json('POST', '/session/basket_items', params, true)

    return data
  }

  async getBasket(params) {
    const data = await this.json('GET', '/session/basket_items', params)

    return data
  }

  async mergeBasket(params) {
    const data = await this.json('POST', '/session/basket_items/merge', params)

    return data
  }

  async resolveBasket(params) {
    const data = await this.json('POST', '/session/basket_items/resolve_conflict', params)

    return data
  }

  async emptyBasket(userId) {
    const data = await this.json('DELETE', `/session/basket_items?${userId}`)

    return data
  }

  async removeBasketItem(basketItemId) {
    const data = await this.json('DELETE', `/session/basket_items/${basketItemId}`)

    return data
  }

  // REFERRAL CARDS
  async getReferralCards(code) {
    const data = await this.json('GET', `/session/referral_card_amount?code=${code}`)

    return data
  }

  // ORDERS
  async orderEvaluate(params) {
    const data = await this.json('POST', '/session/order/evaluate', params, true)

    return data
  }

  async orderSave(params) {
    const data = await this.json('POST', '/session/order/save', params)

    return data
  }

  async getOrderHistory() {
    const data = await this.json('GET', '/user/orders')

    return data
  }

  async getSingleOrder(orderId) {
    const data = await this.json('GET', `/user/orders/${orderId}`)

    return data
  }

  async getPendingOrder(orderProcessingId) {
    const data = await this.json('GET', `/session/order/${orderProcessingId}`)

    return data
  }

  async getDirectPaymentOrder(orderId) {
    const data = await this.json('POST', `/session/order/direct_payment/${orderId}`)

    return data
  }

  async nexusSessionEvent(params) {
    await this.json('POST', '/session/event', params)
  }

  // TINY LINKS
  async createShortLink() {
    const data = await this.json('POST', `/tiny_links`)

    return data
  }

  async encodeShortLink(url) {
    const data = await this.json(
      'GET',
      `/session/tiny_links/encode${toQueryString(url && { url })}`,
    )

    return data
  }

  async decodeShortLink(params) {
    const data = await this.json('GET', `/session/tiny_links/decode${toQueryString(params)}`)

    if (data.token) {
      this.log('DecodeShortLink', data.token)
      this.sessionToken = data.token

      if (!clientSide) {
        this.cookies.set('SESSION_TOKEN', data.token, {
          ...cookieOpts,
          expires: new Date(new Date().getTime() + cookieExpiryTimes.session),
        })
      }
    }

    if (data.affiliateCode) {
      this.cookies.set('AFFILIATE_CODE', data.affiliateCode, {
        ...cookieOpts,
        expires: new Date(new Date().getTime() + cookieExpiryTimes.session),
      })
    }

    return data
  }

  async geoCheck() {
    const data = await this.json('GET', '/session/tiny_links/geo_check')

    return data
  }

  // NOTIFICATION

  async createNotification(params) {
    const data = await this.json('POST', '/session/product_variant_notifications', params)

    return data
  }

  async getNotification(email, variantId, type) {
    const data = await this.json(
      'GET',
      `/session/product_variant_notifications?email=${email}&productVariantId=${variantId}&type=${type}`,
    )

    return data
  }

  // KLAVIYO
  async klaviyoTrack(queryString) {
    await this.json('GET', `/session/klaviyo/track${queryString ? `?${queryString}` : ''}`)
  }

  // WALLET
  async requestPayout(params) {
    const data = await this.json('POST', `/wallet/payout/request`, params)

    return data
  }

  async getPayoutHistory() {
    const data = await this.json('GET', '/wallet/payout/history')

    return data
  }

  // EBBOT
  async ebbotInit(sessionId) {
    await this.request('POST', '/session/ebbot/init', {
      ebbot_session_id: sessionId,
    })
  }
}

const getApiClient = () => {
  let apiClient = false

  if (clientSide) {
    if (window.ApiClient) {
      apiClient = window.ApiClient
    } else {
      apiClient = new ApiClient()
      window.ApiClient = apiClient
    }
  } else {
    apiClient = new ApiClient()
  }

  return apiClient
}

export default getApiClient
